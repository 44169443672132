body {
  font-family: 'Source Sans Pro', sans-serif;
  background: #009de0;
  color: #fff;
  font-size: 1.2em;
}
a {
  color: #fff;
  text-decoration: none;
}
header img {
  max-width: 200px;
}
h1 {
  display: none;
}
#telford_web_design,
footer {
  padding: 20px;
}
@media (max-width: 679px) {
  header {
    text-align: center;
  }
}
